.page-login {

    min-height: 101vh;

    background-size    : cover;
    background-position: 50%;
    background-image   : url(../assets/login-bg.jpg );

    header.topbar {
        background-image: none;
        padding         : 1rem;

        .iwicon,
        .svgicon {
            display: inline-block;

            img,
            svg {
                height      : 2.5rem !important;
                width       : auto !important;
                margin-right: 1rem;
            }
        }
    }

    main {
        margin          : $grid-gutter-width;
        display: flex;
        flex-direction: row-reverse;
    }

    .login-panel{
        border: none;
        margin-bottom: 0;
        width: 100%;

        strong.iw{
            color: $orange;
            font-size: $h1-font-size;
            text-transform: uppercase;
        }

        strong.dna{
            color: $orange;
            font-size: $h4-font-size;
        }
    }
}

@include media-breakpoint-up(md) {

    .page-login {
        position: relative;
        
        header.topbar{
            position: absolute;
        }

        main{
            margin: 0 !important;
        }

        .login-panel{
            width: 33%;

            min-height: 101vh;
        }


    }

}