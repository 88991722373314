.draggable-container i:hover {
    cursor:move;
  }

  .draggable-container i {
    background-color: #333A40;
color: white;
font-size: 20px;
display: flex;
justify-content: center;
align-items: center;
border-right: 1px solid grey;
  }

.ag-dnd-ghost {
  position:relative !important;
}