.dropdown{

    .dropdown-item[data-bs-toggle='collapse']{
        padding-right: 1.25rem;

        &:not(.collapsed) {            
        
            &::after {
              background-image: escape-svg($accordion-button-active-icon);
              transform: $accordion-icon-transform;
            }
        }

        &::after {
            display: inline-block;
            width: 1em;
            height: 1.5em;
            content: "";
            background-image: escape-svg($accordion-button-icon);
            background-repeat: no-repeat;
            background-size: 1em;
            background-position: 50%;
            @include transition($accordion-icon-transition);
            float: right;
            margin-right: -1rem;
        }
    }

    .dropdown-submenu{
        list-style: none;
        padding-left: 0;

        border-top: 1px solid $gray-400;
        border-bottom: 1px solid $gray-400;

        &>li>.dropdown-item{
            &:before{
                content: ' - ';
            }
        }
    }

}