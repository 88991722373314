profile-login{
    width: 100%;
    text-align: right;
    display: block;
}

.logging-in .main-body{
    padding-top: 110px;
}

.card.login-box{
    text-align: left;
    background-color: #fff;
    background-image: none;
    left: 0;
    right: 0;
    width: 100%;
    overflow: visible!important;
    padding-top: 0!important;
    position: relative;
    border-radius: 0;
} 


@include media-breakpoint-up("md"){

    .logging-in .main-body{
        max-width: unset;
        padding: 0;
        width: 100%;
    }

    .card.login-box{
        left: unset;
        max-width: 400px;
        display: inline-block;
        min-height: 101vh;
    }

}