div.search-select{

    input[type=text]{
        float: none !important;
        position: relative !important;
        display: block !important;
        width: 100%;
    }

    ul{
        display: block;
        position: absolute;
        list-style: none;
        background-color: #ffffffee;
        border: 1px solid #efefef;
        border-top: none;
        padding: 2px;
        width: 100%;
        z-index: 1000;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);

        li{
            display: block;
            text-align: left;
            padding: 0.25rem;
            cursor: pointer;

            &:hover{
                background-color: #ff660033;
                color: #fff;
            }

            &.selected{
                background-color: #ff660099 !important;
                color: #fff;
            }
        }
    }
}