label{
    font-weight: $font-weight-bold;
}

select.form-control{
    padding-top: 0;
    padding-bottom: 0;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  //border-color: rgba(126, 239, 104, 0.8);
  box-shadow: none;
  outline: 0 none;
  border:1px solid $orange;
}