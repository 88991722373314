.legal{
    section{
        clear: both;
        margin: 4rem 0;
    }
    .legal-summary{
        padding: 10px;
        width: 33%;
        max-width: 400px;
        margin: 0 0 1rem 1rem;
        float: right;
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.30);
        .legal-summary-inner{
            overflow-x: hidden;
        }
    }
}