/*

 Main app header
===============

Relates to the angular header-main tags
Contains page title, breadcrumbs and main app naviagtion

*/


// $header-margin-top-sm   : 3.5rem;
// $header-margin-top-md   : 1.75rem;
// $header-margin-bottom-sm: 3.5rem;
// $header-margin-bottom-md: 1.75rem;

// header-main{
//     width: 100%;
//     position: static;

#profile-menu {
    top: unset;
    right: 10px;
    left: unset;
    margin-top: unset;
}

header {
    display : block;
    width   : 100%;
    //height:130px;
    @include gradient-x($gray-900, $gray-700, 0%, 100%);
    //@include gradient-x(#212529, #495057 , 0%, 100%);
    //position: fixed;
    padding-left: 20px;
    z-index: $zindex-fixed;

    #main-header {
        .navbar-brand svg {
            width: 50px;
        }
    }


    #secondary-header {
        display       : flex;
        flex-direction: row-reverse;
        align-items   : center;

        .page-titles {
            flex: 1;
        }

        .navbar.secondary-navbar {

            .nav-item {
                i {
                    display: inline-block !important;
    
                    &:before {
                        font-size: $h2-font-size;
                    }
                }
    
                span {
                    display: none;
                }
    
            }
        }
    }


    li>.nav-link:link,
    li>.nav-link:visited {
        //font-size: $font-size-sm;
        font-size     : 0.65625rem;
        text-transform: uppercase;
        color         : $gray-200;

        &.dropdown-toggle:after {
            content: none;
        }
    }

    li>.nav-link:hover,
    li>.nav-link:active {
        text-decoration: $link-hover-decoration;
        color          : $link-color;
    }



    /* Desktop variations */
    @include media-breakpoint-up("md") {

        #secondary-header .container-lg {

            .dropdown-menu {
                left : unset;
                right: 0;
            }
        }


        // .nav-link {
        //     i {
        //         display: none !important; //hide menu item icons on desktop
        //     }

        //     span {
        //         display  : inline;
        //         font-size: $font-size-base;
        //     }
        // }

        .navbar-brand img {
            width: 100px;
        }

        .dropdown .dropdown-menu {
            //override menu position on desktop
            bottom: auto;
            top   : 100%;
        }

        .dropup .dropdown-toggle {
            @include caret(); //reset caret direction to match the overridden menu position
        }

        //h2 {
        //    @include sr-only();
        //}

        ol.breadcrumb {
            display: flex;
        }
    }


    @include media-breakpoint-down("sm") {

        #secondary-header .secondary-navbar {
            flex-wrap: nowrap;

            .navbar-nav {
                flex-direction: row;
            }
        }
    }
}