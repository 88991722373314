.numbered{
    counter-reset: section;

    .numbered-item-level-1{
        &:before{
            counter-increment: section;
            content: counter( section ) " ";
        }
        counter-reset: subsection1;
    }

    .numbered-item-level-2{
        &:before{
            counter-increment: subsection1;
            content: counter( section ) "." counter( subsection1 ) " ";
        }
        counter-reset: subsection2;
    }

    .numbered-item-level-3:before{
        counter-increment: subsection2;
        content: counter( section ) "." counter( subsection1 ) "." counter( subsection2 ) " ";
    }
}