.modal.hidden,.modal-backdrop.hidden{
    z-index: -1 !important;
}


.modal-panel{
    //display: block;
    width: 90%;
    max-width: 800px;   
    /* top: 119px; */
    top: 0;
    position: absolute !important;
    bottom: 0;
    background-color: #fff;
    min-height: 100vh;

    @include media-breakpoint-up( "md" ){
        width: 75%;
    }

    @include media-breakpoint-up( "xl" ){
        width: 50%;
    }
}


.modal-panel-right{
    right: 0;
    left: unset;
    box-shadow: -1px 0 3px rgba(0,0,0,0.2);

    &.show{
        animation: slideLeft 0.5s;
        animation-fill-mode: forwards;
    }
}

@keyframes slideLeft{
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0);
    }
}

.modal-panel .modal-dialog{
    width: 100%;
    /* border: none !important; */
    border-radius: 0 !important;
    max-width: none;
}

.modal-panel .modal-dialog .modal-content,
.modal-panel .modal-dialog .modal-header{
    border: none;
}