.nav-tabs{

    margin-bottom: 2rem;

    .nav-item{

        margin-right:  0.25rem;

        .nav-link{

            border-bottom-width: 3px;
            color: $link-hover-color;
            padding: $input-btn-padding-y;

            @include transition( $transition-base );

            &.active{
                font-weight: bold;
            }
        }
    }
}