table.dataTable{

     td.details-control{
        text-align: center;
         background-image: none !important; //remove default icons
         &:after{
            font-family: "Material Design Icons";
            content: "\F417";
         }
     }

     .shown .details-control:after{
         content: "\F376";
     }
}