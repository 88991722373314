table.selectable-table tr{

    outline:none;
    
    &:hover{
        background-color: #ecebe9;
        cursor: pointer;
    }

    &.isSelected{
        font-weight:bolder;
        box-shadow: inset 5px 0px 0px 0px #FF7316;
    }
}