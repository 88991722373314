.star-rating{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .rating-stars {
        font-size: 20px;
        margin-left:5px;
        margin-right:5px;
    }

    .rating-review-count{
        font-size: $font-size-sm;
    }
}
