.hb > span{
    display: block;
    position: absolute;
    margin: 0;
    padding: 0;
    width: 50%;
    top: 0;
    left: 25%;
    padding-top: 86.60254%; //height of a regular hexagon relative to the width of its bounding box
    height: 0;
    background-color: #1e88e5;
    transition: background-color 0.5s;
}

.hb > span:after, .hb > span:before{
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
    z-index: 0;
    top: 0;
    height: 100%; 
    transform-origin: 50% 50%;
    background-color: #1e88e5;
    transition: background-color 0.5s;
}

.hb > span:before{
    transform: rotate( -60deg );
}

.hb > span:after{
    transform: rotate( 60deg );
}

.hb > span i{
    color: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    transform: translateY( -50% );
    z-index: 2;
}

.hb > span:hover, .hb > span:focus,
.hb > span:hover:before, .hb > span:focus:before,
.hb > span:hover:after, .hb > span:focus:after{
    background-color: #f60 !important;
}

.category-risk.hb > span,
.category-risk.hb > span:before,
.category-risk.hb > span:after{
    background-color: theme-color('risk');
    border:none;
}

.category-peopleandculture.hb > span,
.category-peopleandculture.hb > span:before,
.category-peopleandculture.hb > span:after{
    background-color: theme-color('people');
    border: none;
}

.category-workplaceandfacilities.hb > span,
.category-workplaceandfacilities.hb > span:before,
.category-workplaceandfacilities.hb > span:after{
    background-color: theme-color('property');
    border: none;
}

.category-personaldevelopment.hb > span,
.category-personaldevelopment.hb > span:before,
.category-personaldevelopment.hb > span:after{
    background-color: theme-color('performance');
    border: none;
}

.category-overall.hb > span,
.category-overall.hb > span:before,
.category-overall.hb > span:after{
    background-color: theme-color('overall');
    border: none;
}