.progress{
    position:relative;
    overflow: visible;
    margin-bottom: 1rem;
    margin-top: 1rem;

    .progress-bar{
        overflow:visible;
        position:relative;
        border-radius: 3px !important;

        &:not(.no-animation){
            animation: prog 2s ease-out;
            animation-fill-mode: forwards;
        }
    }

    .progress-bar[data-label]:after{
        content: attr(data-label);
        display: block;
        background-color: #f60;
        overflow:visible;
        height: 26px;
        line-height: 26px;
        background-color: inherit;
        position: absolute;
        border-radius: 5px !important;
        padding: 0 7px;
        font-size: $font-size-base;
        left: 100%;
        transform: translateX(-50%);
        //opacity: 0;
    }

    .progress-bar[data-label]:not(.no-animation):after{
        opacity: 0;
        animation: progLabel 0.5s 2s ease-in-out;
        animation-fill-mode: forwards;
    }

    //label positioning
    .progress-bar{
        &.prog-0, &.prog-1, &.prog-2, &.prog-3, &.prog-4, &.prog-5, &.prog-6, &.prog-7, &.prog-8, &.prog-9,
        &.prog-10, &.prog-11, &.prog-12, &.prog-13, &.prog-14, &.prog-15, &.prog-16, &.prog-17, &.prog-18, &.prog-19,
        &.prog-20, &.prog-21, &.prog-22, &.prog-23, &.prog-24, &.prog-25, &.prog-26, &.prog-27, &.prog-28, &.prog-29,
        &.prog-30, &.prog-31, &.prog-32, &.prog-33{

            &:after{
                left: 100% !important;
                right: unset;
                transform: translateX(0);
            }
        }

        &.prog-67, &.prog-68, &.prog-69,
        &.prog-70, &.prog-71, &.prog-72, &.prog-73, &.prog-74, &.prog-75, &.prog-76, &.prog-77, &.prog-78, &.prog-79,
        &.prog-90, &.prog-91, &.prog-92, &.prog-93, &.prog-94, &.prog-95, &.prog-96, &.prog-97, &.prog-98, &.prog-99,
        &.prog-90, &.prog-91, &.prog-92, &.prog-93, &.prog-94, &.prog-95, &.prog-96, &.prog-97, &.prog-98, &.prog-99,
        &.prog-100{

            &:after{
                right: 0 !important;
                left: unset;
                transform: translateX(0);
            }
        }
    }
}

@keyframes prog {
    0% {
        width: 0;
    }
    50%{
        width: 0;
    }
    100%{
    }
}

@keyframes progLabel{
    0%{
        opacity: 0;
    }
 
    100%{
        opacity: 1;
    }
} 


@for $i from 0 through 100 {
    .prog-#{$i} { width: $i * 1%; }
}