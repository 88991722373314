main .card {
  margin-bottom: 1rem;

  &:not(.no-shadow), &:not(.card-invisible){
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.30);
    border: none;
  }

  &.card-invisible{
    box-shadow: none;
    border: none;    
  }


  h2 i:hover {
    cursor:pointer;
    color:$orange;
  }
  
  .card-title {

    h2 {
    line-height:1;
    font-size:15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    }

    small,
    strong {
      display      : block;
      text-align   : center;
      margin-bottom: $spacer/2;
    }
  }
}

.card-with-control-buttons {
  display       : flex;
  flex-direction: row-reverse;

  .card-header {
    padding-left: 2px;

    .btn-group {
      padding: 0;

      .btn {
        padding-left : 2px;
        padding-right: 2px;
      }
    }
  }

  .card-body {
    padding-right: 2px;
  }


}


.accordion {
  display: block;

  margin-bottom: 1rem;

  >.card {
    background      : transparent;
    background-image: none;
    border-radius   : 0;

    >.card-header {
      border-bottom: 1px solid $gray-600;
      padding      : 0;
      margin       : 0;

      button,
      a.btn {
        display    : block;
        width      : 100%;
        padding    : 0.5rem 0.25rem !important;
        font-weight: bold;
        text-align : left;
        font-size  : $font-size-base;
        position   : relative;

        &:focus {
          box-shadow: none;
        }
      }

    }
  }
}


@include media-breakpoint-up("md") {
  .card {
    .card-header {
      border-bottom : 1px solid rgba(255, 255, 255, 0.4);
      padding-bottom: 1rem;
      font-weight   : bolder;
    }
  }
}


.card {

  border: unset;
  background-color: #F9F9F9;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.30);
  //padding-bottom: 0.5rem;

  & > *:last-child:not(.activity-title){
    margin-bottom: 0.5rem;
  }

  .activity-title::before {
    content:" ";
    position: absolute;
    background-color: rgba( $gray-800 , 0.8 );
    top:0;
    left:0;
    width:100%;
    height:100%;
  }

  .activity-title {
    /*height: 160px;*/
    height:auto;
    width: 100%;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow: hidden;
    background-color: $gray-800;
    position:relative;

    .activity-meta{
      min-height: 200%;
      margin: -20px 0 -20px -20px;
      padding: 20px;
      z-index:2;
    }

    button{
      margin-left: 1rem;
      z-index:2;
    }

    & > :first-child{
      color: #fff;
      flex-grow: 1;
    }
    .card-title{
      font-weight: bold;
    }
  }

  .activity-title + .card-footer{
    margin-top: 1rem;
  }

  .card-footer{
    border: unset;
    padding-top: 0;
    padding-bottom: 0;
  }

  .activity-description{
    position: static;
    height: 8rem;
    box-sizing: content-box;
    overflow: hidden;
  }
}