/// .btn-text {
///   background: transparent;
///   border: none;
///   @each $color,
///   $value in $theme-colors {
///     &.btn-text-#{$color} {
///       @include button-variant($value);
///     }
///   }
/// }

.btn-toolbar{
  @extend .btn-info;
}

.btn-toolbar:not(.btn-attached){
  background-color: white;
  color: black;
  border-color: white;

  &.dropdown-toggle{
    display: inline-block;
  }

  display: inline-block;

  &:disabled{
    color: gray !important;
  }

  &:hover, &:focus, &:active{
    background-color: white;
    color: #f60;
  }
}

.btn-group{
  display: flex;
  flex-direction: column;
  gap: $spacer/4;
  .btn{
    flex: 1 1 0;
  }
  //flex-grow: 1;

  // .btn{
  //   width: 100%;
  //   margin-bottom: $spacer/4;
  // }

  @include media-breakpoint-up(md){
    flex-direction: row;
  }
}