.tools{
    display: flex;
    flex-direction: row;

    &:last-child{
        margin-bottom: 1rem;
    }
}

.tools-filters .filter{
    margin-right: 3px;
}
.tools-filters .filter .form-group{
    margin-bottom: 0;
    display:flex;
}

.tools-filters .filter .form-check-inline{
    padding-top: $btn-padding-y;
    padding-bottom: $btn-padding-y;
    label{
        font-weight: normal;
        font-size: $btn-font-size;
        line-height: $btn-line-height;
    }
}




.tools-filters-active{
    margin-top: 0.5rem;
}
.tools-filters-active .active-filter{
    margin-right: 0.25rem;
}
.tools-filters-active .active-filter a:link{
    color: #fff;
}

.tools-filters-active .active-filter a:hover,.tools-filters-active .active-filter a:focus{
    transition: opacity 0.5s;
    opacity: 0.5;
}