.application > section > header {
    color: #fff;
    text-align: center;
    display: block;
    position: relative;
    padding: 2rem 0 1rem 0;
    background-position: -7000%;
    background-repeat: no-repeat;
    background-color: #f60;
    padding-left:none;
}

.application > section > header:after {
    content: '';
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 20%;
    background-color: green;
    background-image: inherit;
    background-size: cover;
    background-attachment: fixed;
    bottom: -1rem;
}

.application section {
  text-align:center;
}

.application section p {
  margin: 10px;
  text-align:center;
}

.application section button {
  width: 150px;
  margin: 4px;
}

.application > header {
  display: block;
    width: 100%;
    background-image: linear-gradient(to right,#212529 0,#495057 100%);
    background-repeat: repeat-x;
    z-index: 1030;
    text-align:center;
    color:white;
}

.application > header h1 {
  margin:0;
  font-size: 17px;
  font-weight: lighter;
}

.collapse-label{ display: inline; }

.collapsed-label{ display: none; }

.collapsed .collapse-label, .collapsing .collapse-label{ display: none; }

.collapsed .collapsed-label, .collapsing .collapsed-label{ display: inline }

 