body{
    height: 100vh;
    overflow-y: hidden;
}

#page-wrapper{
    position: relative;
    height: calc( 100vh - 118px );
    overflow-y: auto;

    & > * {
        margin-top: 1.75rem;
        margin-bottom: 1.75rem;
    }

    main{
        margin-top: 0;
        padding-top: 1.75rem;
    }

    main .row + .row{
        margin-top: 2rem;
    }
}

@include media-breakpoint-down("sm"){

    html{
        font-size: 14px;
    }

    body{
        background-color: white;
    }

    #page-wrapper{
        height: calc( 100vh - 132px );    
    }
}

:focus-visible{
    outline: 3px solid rgba( $orange , 0.4 ) !important;
    outline-offset: -3px;
    //background-color: red !important;
}

a:not(.btn):focus-visible{
    outline-offset: 0;
}