@font-face {
  font-family: 'wpdna-icons';
  src:  url('workplacedna-icon-font/wpdna-icons.eot?gwq9c4');
  src:  url('workplacedna-icon-font/wpdna-icons.eot?gwq9c4#iefix') format('embedded-opentype'),
    url('workplacedna-icon-font/wpdna-icons.ttf?gwq9c4') format('truetype'),
    url('workplacedna-icon-font/wpdna-icons.woff?gwq9c4') format('woff'),
    url('workplacedna-icon-font/wpdna-icons.svg?gwq9c4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@import "wpdna-icons-styles";