.dropdown-filters{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.dropdown-filters > *{
    flex-grow: 1;
}

.filters-bar{
    display: flex;
    flex-direction: row;

    .filter{
        margin-right: 3px;
    
        .form-group{
            margin-bottom: 0;
        }
    }
}



.filters-active{
    margin-top: 0.5rem;
    .active-filter{
        margin-right: 0.25rem;
        
        a:link{
            color: #fff;
        }
        a:hover, a:focus{
            transition: opacity 0.5s;
            opacity: 0.5;
        }
    }
}