.action-buttons{
    // display: flex;
    // flex-direction: row;
    // align-items: stretch;

    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(160px, 1fr));
}

.action-button{

    border: none;
    background: transparent;
    box-shadow: none;
    color: inherit;
    position: relative;
    text-decoration: none !important;
    display: flex;
    flex-direction: column;

    align-items: center;

    &:before{
        @extend .wpdna-icon;
        @extend .wpdna-icon-hexagon:before;
        font-size: 72px;
        position: relative;
        color: $body-color;
        text-shadow: 0 0 3px rgba(0,0,0,0.5);
        transition: color 0.5s ease-in-out;
    }

    &:hover, &:focus{
        &:before{
            color: $link-color;
        }
    }

    i{ 
        color: #fff;
        position: absolute;
        font-size: 36px;
        top: 11px; //I have no idea why top 11px is working to vertically center these icons!
        left: 50%;
        transform: translateX(-50%);
    }
    span{
        padding: 0.25rem 1rem;
        text-align: center;
    }
}