@mixin make-widget-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
    @each $breakpoint in map-keys($breakpoints) {
      $infix: breakpoint-infix($breakpoint, $breakpoints);
  
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        // Provide basic `.col-{bp}` classes for equal-width flexbox columns
        .widget#{$infix} {
          flex: 1 0 0%; // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
        }
  
        .widgets#{$infix}-auto > * {
          @include make-col-auto();
        }
  
        @if $grid-row-columns > 0 {
          @for $i from 1 through $grid-row-columns {
            .widgets#{$infix}-#{$i} {
              @include row-cols($i);
            }
          }
        }
  
        .widget#{$infix}-auto {
          @include make-col-auto();
        }
  
        @if $columns > 0 {
          @for $i from 1 through $columns {
            .widget#{$infix}-#{$i} {
              @include make-col($i, $columns);
            }
          }
        }
  
        // Gutters
        //
        // Make use of `.g-*`, `.gx-*` or `.gy-*` utilities to change spacing between the columns.
        @each $key, $value in $gutters {
          .g#{$infix}-#{$key},
          .gx#{$infix}-#{$key} {
            --#{$variable-prefix}gutter-x: #{$value};
          }
  
          .g#{$infix}-#{$key},
          .gy#{$infix}-#{$key} {
            --#{$variable-prefix}gutter-y: #{$value};
          }
        }
      }
    }
  }

  
.widgets {
    @include make-row(3px);

    > * {
        @include make-col-ready(3px);
    }

    .widget{
      
      text-decoration: none;

      margin: 3px 0;

      .widget-inner{
        @extend .flex-wrap;
        font-size: $font-size-sm;
        display: flex;
        flex-direction: row;        
        @extend .card;
        padding: 3px;
        width: 100%;
        margin-bottom: 0 !important;
        height: 100%;

        .widget-title{
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    @each $color, $value in $theme-colors {
      $hover-background: if($color == $color-contrast-light, shade-color($value, $btn-hover-bg-shade-amount), tint-color($value, $btn-hover-bg-tint-amount));
      .widget-#{$color} {
        .widget-inner{
          background-color: $value;
          color: color-contrast($value),
        }
        &:hover, &:focus{
          .widget-inner{
            background-color: $hover-background;
            @include transition($btn-transition);
          }
        }
      }
    }
}

@include make-widget-columns(6,3px);