#sidebar-wrapper {

  @include media-breakpoint-up('md'){
    margin-left:20px;
  }

  margin-top   : 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  align-items: baseline;

  #sidebar {
      //margin-top   : 1.75rem;
      padding-top: 1.75rem;
      // margin-bottom: 1.75rem;
  }
}



#sidebar {

    flex-direction: column;
    align-items   : unset;

    .accordion-item {
        border-width: 0;
    }

    .accordion-header{

      .accordion-button{
        color: $body-color;
        margin: 0;
        border-bottom: 1px solid #6c757d;
        padding: .5rem .25rem!important;
        font-weight: 700;
      
      
        &:focus {
          z-index: 3;
          border-color: none;
          outline: 0;
          box-shadow:none;
          color:black;
          background-color:none;
        }
      }

      & > a.accordion-button{
        &:after{
          content: none;
        }

        &.active{
          color: $orange;
        }
      }
    } 

    .accordion-collapse {
        padding: 0;
    }

    .list-group {
        background-color: transparent;
        border-radius   : 0;

        .list-group-item {
            background-color: transparent;
            border          : none;
            border-bottom   : 1px solid $gray-500;
            padding         : 0.5rem 0.25rem 0.5rem 1rem;
        }
    }
}

@include media-breakpoint-up("md") {

    #page-wrapper {
        display       : flex;
        flex-direction: row;
    }



    #sidebar {

        width: 300px;
    }

    main {
        flex-grow: 1;
    }
}

// @media (min-width: 768px) and (max-width: 1024px) {
@include media-breakpoint-between(md,xl){

    #sidebar {
      width: 43px;
      transition: 0.3s width ease-in-out, 0.3s padding-right ease-in-out, 0.3s margin-right ease-in-out, 0.3s box-shadow ease-in-out;
      padding-right: 0;
      position: relative;
      background-color: white;
      z-index: 2;
      overflow: hidden;
      white-space: nowrap;
      z-index: 10;
      min-height: calc( 100vh - 118px );
      box-shadow: 5px 2px 8px rgba(0,0,0,0);
    }

    #sidebar i {
      font-size: 24px;
      line-height: 43px;
      width: 43px !important;
      min-width: 43px;
      text-align: center;
      display: inline-block;
      box-sizing: border-box;
    }

    #sidebar.hasFocus,
    #sidebar:hover {
      width: 300px !important;
      margin-right: -257px;
      padding-right: 30px;
      box-shadow: 5px 2px 8px rgba(0, 0, 0, 0.1 );
    }

    #sidebar>.accordion-item>.accordion-header .accordion-button {
      line-height: 43px;
      padding: 0 !important;
    }

    #sidebar>.accordion-item>.accordion-header .accordion-button:after {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    #sidebar.hasFocus>.accordion-item>.accordion-header .accordion-button:after,
    #sidebar:hover>.accordion-item>.accordion-header .accordion-button:after {
      opacity: 1;
    }

    #sidebar>.accordion>.accordion-header a p {
      margin: 0;
    }
  }