.offcanvas.offcanvas-details{
    width: 90%;
    max-width: 800px;   

    @include media-breakpoint-up( "md" ){
        width: 75%;
    }

    @include media-breakpoint-up( "xl" ){
        width: 50%;
    }
}