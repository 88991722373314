.wpdna-icon{
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'wpdna-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wpdna-icon-risk:before {
  content: "\e902";
}
.wpdna-icon-hexagon:before {
  content: "\e900";
}
.wpdna-icon-workplace-and-facilites:before {
  content: "\e901";
}
.wpdna-icon-personal-development:before {
  content: "\e903";
}
.wpdna-icon-people-and-culture:before {
  content: "\e904";
}
