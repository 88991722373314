.chart{
    width: 100%;
    position: relative;
    margin: 0;
}

.chart-responsive{
    width: 100%;
    height: 0;
    position: relative;
    margin: 0;
}

.chart169{
    padding-top: 56.25%;
}

.chart43{
    padding-top: 75%;
}

.chart31{
	padding-top: 33.333%;
}

.chart-loading{
	padding-top: 0 !important;
}

.chart-responsive canvas{
    position: absolute;
    top: 0;
    left: 0;
}

/* Chart JS styles start here*/

/*
 * DOM element rendering detection
 * https://davidwalsh.name/detect-node-insertion
 */
 @keyframes chartjs-render-animation {
	from { opacity: 0.99; }
	to { opacity: 1; }
}

.chartjs-render-monitor {
	animation: chartjs-render-animation 0.001s;
}

/*
 * DOM element resizing detection
 * https://github.com/marcj/css-element-queries
 */
.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
	position: absolute;
	direction: ltr;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	pointer-events: none;
	visibility: hidden;
	z-index: -1;
}

.chartjs-size-monitor-expand > div {
	position: absolute;
	width: 1000000px;
	height: 1000000px;
	left: 0;
	top: 0;
}

.chartjs-size-monitor-shrink > div {
	position: absolute;
	width: 200%;
	height: 200%;
	left: 0;
	top: 0;
}
