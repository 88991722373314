.review{
    display: flex;
    flex-direction: row;
    align-items: center;

    .review-profilepic{
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        flex:none;
    }   
    .review-body{
        flex-grow: 1;
        font-size: $font-size-sm;
        margin: 0 10px;
        min-width: 0;

        q{
            display: block;        
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .review-reply{
        font-size: 20px;
        padding: 0;
    }
}